import { Component, OnInit } from '@angular/core';
import { environment } from "../environments/environment";
import { GoogleAnalyticsService } from "./shared/services/google-analytics.service";
import { HotjarService } from "./shared/services/hotjar.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, map, mergeMap } from "rxjs";
import { SEOService } from "./shared/services/seo.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  showAppConfig = !environment.production;

  constructor(private readonly googleAnalyticsService: GoogleAnalyticsService, private readonly hotjarService: HotjarService, private readonly router: Router, private readonly activatedRoute: ActivatedRoute, private readonly seoService: SEOService) {
    if (environment.hotjarTrackingCode && environment.hotjarVersion) {
      this.hotjarService.initialize();
    }
  }

  ngOnInit() {
    if (environment.googleAnalyticsId) {
      this.googleAnalyticsService.initialize();
    }

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this.seoService.updateTitle(event['title']);
        this.seoService.updateOgUrl(event['ogUrl']);
        //Updating Description tag dynamically with title
        this.seoService.updateDescription(event['title'] + event['description'])
      });
  }
}
