<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
  <div class="grid">
    <div class="col-12 md:col-6">

      <div class="p-fluid pr-0 md:pr-6">
        <div class="mb-4" *ngIf="showSuccess">
          <p-message severity="success" i18n-text text="Your message has been sent!"></p-message>
        </div>
        <div class="mb-4" *ngIf="showError">
          <p-message severity="error" i18n-text text="There was an error sending your message. Please try again."></p-message>
        </div>
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
          <div class="field" >
            <label for="name" class="font-medium" i18n>Name</label>
            <input id="name" formControlName="name" type="text" pInputText class="py-3 px-2 text-lg">
            <app-form-error formControlName="name"></app-form-error>
          </div>
          <div class="field">
            <label for="email" class="font-medium" i18n>Email *</label>
            <input id="email" formControlName="email" type="text" pInputText class="py-3 px-2 text-lg">
            <app-form-error formControlName="email"></app-form-error>
          </div>
          <div class="field">
            <label for="phone" class="font-medium" i18n>Phone</label>
            <input id="phone" formControlName="phone" type="text" pInputText class="py-3 px-2 text-lg">
            <app-form-error formControlName="phone"></app-form-error>
          </div>
          <!--<div class="field">
            <label for="company" class="font-medium">Company</label>
            <input id="company" type="text" pInputText class="py-3 px-2 text-lg">
          </div>-->
          <div class="field">
            <label for="message" class="font-medium">Message *</label>
            <textarea id="message" formControlName="message" pInputTextarea rows="6" [autoResize]="true" class="py-3 px-2 text-lg"></textarea>
            <app-form-error formControlName="message"></app-form-error>
          </div>
          <p-button pRipple i18n-label label="Send Message" icon="pi pi-send" class="w-auto" type="submit" [loading]="isSubmitting" [disabled]="!contactForm.valid"></p-button>
        </form>
      </div>
    </div>
    <div class="col-12 md:col-6 bg-no-repeat bg-right-bottom"> <!--  style="background-image: url('assets/images/blocks/contact/contact-1.png')" -->
      <div class="text-900 text-2xl font-medium mb-6" i18n>Get in Touch with the Padel Pros!</div>
      <div class="text-700 line-height-3 mb-6" i18n>Have questions, suggestions, or looking to join the league? We're here to serve you the answers. Drop us a message and we'll rally back to you as soon as we can!</div>
      <!--<a class="inline-flex align-items-center text-blue-600 font-bold no-underline cursor-pointer">
        <span class="mr-3">View Address on Google Maps</span>
        <i class="pi pi-arrow-right"></i>
      </a>-->
      <ul class="list-none p-0 m-0 mt-6 text-700">
        <li class="flex align-items-center mb-3">
          <i class="pi pi-phone mr-2"></i>
          <span>+49 174 3253450</span>
        </li>
        <li class="flex align-items-center">
          <i class="pi pi-inbox mr-2"></i>
          <a href="mailto:contact@padel-tennis-league.com">contact&#64;padel-tennis-league.com</a>
        </li>
      </ul>
    </div>
  </div>
</div>
