import { Component } from '@angular/core';
import { RippleModule } from "primeng/ripple";
import { StyleClassModule } from "primeng/styleclass";
import { RouterLink } from "@angular/router";
import { ButtonModule } from "primeng/button";

@Component({
  selector: 'b2c-padel-nav-bar',
  standalone: true,
  imports: [
    RippleModule,
    StyleClassModule,
    RouterLink,
    ButtonModule
  ],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent {

}
