<div class="relative px-4 overflow-hidden">
  <div class="absolute top-0 left-0 w-full video-container" >
    <div></div>
    <video playsinline autoplay loop poster="assets/img/padel-video-placeholder.jpg" onloadedmetadata="this.muted=true" oncanplay="this.play()">
      <source src="assets/video/padel-league/hero.mp4" type="video/mp4">
    </video>
    <div class="video-overlay"></div>
    <nav class="navbar">
      <div class="py-3 px-4 md:px-6 shadow-2 flex align-items-center justify-content-between relative bg-black-alpha-50">
        <img src="assets/img/cs-logo-padel.png" alt="Image" height="50" routerLink="/" class="cursor-pointer">
        <a pRipple class="cursor-pointer block lg:hidden text-gray-400" pStyleClass="@next" enterClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true">
          <i class="pi pi-bars text-4xl text-white"></i>
        </a>
        <nav class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 px-4 md:px-6 lg:px-0 shadow-2 lg:shadow-none lg:bg-transparent" style="background-color: rgba(0, 0, 0, 0.5);">
          <ul class="list-none p-0 m-0 lg:m-auto flex lg:align-items-center text-gray-400 select-none flex-column lg:flex-row">
          </ul>
          <div class="flex justify-content-between lg:block border-top-1 lg:border-top-none py-3 lg:py-0 mt-3 lg:mt-0" style="border-color: rgba(222, 226, 230, 0.15)">
            <p-button pRipple label="Log In" icon="pi pi-chevron-right" iconPos="right" styleClass="p-button-rounded font-bold" routerLink="/login"></p-button>
          </div>
        </nav>
      </div>

    </nav>
  </div>
  <div class="text-center relative video-text" style="margin-top: 80px">
  </div>
</div>
