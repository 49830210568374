import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactPageComponent } from "./features/contact/pages/contact-page/contact-page.component";
import { MainLayoutComponent } from "./layout/components/main-layout/main-layout.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/legal/legal.module').then(m => m.LegalModule),
      },
      {
        path: 'contact-us',
        component: ContactPageComponent,
        data: {
          title: $localize `Contact Us`,
          description: $localize `Get in touch with the Padel Pros`,
        }
      },
      {
        path: 'checkout',
        loadChildren: () => import('./features/checkout/checkout.module').then(m => m.CheckoutModule),
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
