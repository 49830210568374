import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from "./layout/app.layout.module";
import { AppConfigModule } from "./layout/config/app.config.module";
import { GraphQLModule } from "./shared/graphql/graphql.module";
import { HttpClientModule } from "@angular/common/http";
import { AuthModule } from "./features/auth/auth.module";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppLayoutModule,
    AppConfigModule,
    GraphQLModule,
    HttpClientModule,
    AuthModule
  ],
  providers: [
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
